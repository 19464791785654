import React from "react"
import Layout from "../components/layout"
import Masthead from "../components/masthead"
import Navigation from "../components/navigation"
import Portfolio from "../components/portfolio"
import Seo from "../components/seo"

export default function Brands() {
  const title = "Brands"

  return (
    <Layout>
      <Seo title={title} />
      <Navigation />
      <Masthead title={title} />
      <Portfolio />
    </Layout>
  )
}
